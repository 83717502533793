/** @format */

import type { City } from '../types';

export const CITIES_LONG: City[] = [
  {
    name: 'bologna',
    country: 'it',
    hot: false,
    order: 3,
    type: 'dv',
  },
  {
    name: 'bordeaux',
    country: 'fr',
    hot: false,
    order: 2,
    type: 'dv',
  },
  {
    name: 'como',
    country: 'it',
    hot: false,
    order: 8,
    type: 'campus',
  },
  {
    name: 'ferrara',
    country: 'it',
    hot: false,
    order: 9,
    type: 'campus',
  },
  {
    name: 'florence',
    country: 'it',
    hot: false,
    order: 5,
    type: 'dv',
  },
  {
    name: 'lille',
    country: 'fr',
    hot: false,
    order: 3,
    type: 'dv',
  },
  {
    name: 'lisbon',
    country: 'pt',
    hot: false,
    order: 1,
    type: 'dv',
  },
  {
    name: 'lyon',
    country: 'fr',
    hot: true,
    order: 5,
    type: 'dv',
  },
  {
    name: 'madrid',
    country: 'es',
    hot: false,
    order: 1,
    type: 'dv',
  },
  {
    name: 'milan',
    country: 'it',
    hot: true,
    order: 1,
    type: 'dv',
  },
  {
    name: 'montpellier',
    country: 'fr',
    hot: false,
    order: 4,
    type: 'dv',
  },
  {
    name: 'padua',
    country: 'it',
    hot: false,
    order: 7,
    type: 'dv',
  },
  {
    name: 'paris',
    country: 'fr',
    hot: true,
    order: 2,
    type: 'dv',
  },
  {
    name: 'parma',
    country: 'it',
    hot: false,
    order: 10,
    type: 'campus',
  },
  {
    name: 'rome',
    country: 'it',
    hot: true,
    order: 2,
    type: 'dv',
  },
  {
    name: 'siena',
    country: 'it',
    hot: false,
    order: 11,
    type: 'campus',
  },
  {
    name: 'toulouse',
    country: 'fr',
    hot: false,
    order: 6,
    type: 'dv',
  },
  {
    name: 'trento',
    country: 'it',
    hot: false,
    order: 6,
    type: 'dv',
  },
  {
    name: 'trieste',
    country: 'it',
    hot: false,
    order: 13,
    type: 'campus',
  },
  {
    name: 'turin',
    country: 'it',
    hot: false,
    order: 4,
    type: 'dv',
  },
  {
    name: 'venice',
    country: 'it',
    hot: false,
    order: 14,
    type: 'campus',
  },
];

export const CITIES_SHORT: City[] = [
  {
    name: 'dublin',
    country: 'ie',
    hot: false,
    order: 2,
    type: 'altido',
  },
  {
    name: 'ie',
    country: 'ie',
    hot: false,
    order: 1,
    type: 'altido',
  },
  {
    name: 'sco',
    country: 'sco',
    hot: false,
    order: 1,
    type: 'altido',
    href: (locale: string) => `https://book.stayaltido.com/${locale}/properties`,
  },
  {
    name: 'fife',
    country: 'sco',
    hot: false,
    order: 3,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Fife&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'glasgow',
    country: 'sco',
    hot: false,
    order: 4,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Glasgow&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'north-berwick',
    country: 'sco',
    hot: false,
    order: 5,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=North+Berwick&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'en',
    country: 'en',
    hot: false,
    order: 1,
    type: 'altido',
    href: (locale: string) => `https://book.stayaltido.com/${locale}/properties`,
  },
  {
    name: 'coast-and-country',
    country: 'sco',
    hot: false,
    order: 14,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Pitlochry&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'norfolk',
    country: 'en',
    hot: false,
    order: 7,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=King%27s+Lynn&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'london',
    country: 'en',
    order: 15,
    hot: true,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=London&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'plymouth',
    country: 'en',
    hot: false,
    order: 10,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Plymouth&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'west-coast',
    country: 'en',
    hot: false,
    order: 12,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Thullabaun&country=Ireland&minOccupancy=1`,
  },
  {
    name: 'it',
    country: 'it',
    hot: false,
    order: 1,
    type: 'altido',
  },
  {
    name: 'alps',
    country: 'it',
    hot: false,
    order: 2,
    type: 'altido',
  },
  {
    name: 'cinque-terre',
    country: 'it',
    hot: false,
    order: 3,
    type: 'altido',
  },
  {
    name: 'como',
    country: 'it',
    hot: false,
    order: 4,
    type: 'altido',
  },
  {
    name: 'faggeto-lario',
    country: 'it',
    hot: false,
    order: 5,
    type: 'altido',
  },
  {
    name: 'foggia',
    country: 'it',
    hot: false,
    order: 6,
    type: 'altido',
  },
  {
    name: 'genova',
    country: 'it',
    hot: false,
    order: 7,
    type: 'altido',
  },
  {
    name: 'golfo-tigullio',
    country: 'it',
    hot: false,
    order: 8,
    type: 'altido',
  },
  {
    name: 'portofino',
    country: 'it',
    hot: false,
    order: 9,
    type: 'altido',
  },
  {
    name: 'riviera',
    country: 'it',
    hot: false,
    order: 10,
    type: 'altido',
  },
  {
    name: 'rome',
    country: 'it',
    hot: false,
    order: 11,
    type: 'altido',
  },
  {
    name: 'milan',
    country: 'it',
    hot: true,
    order: 12,
    type: 'altido',
  },
  {
    name: 'santa-margherita-ligure',
    country: 'it',
    hot: false,
    order: 13,
    type: 'altido',
  },
  {
    name: 'trieste',
    country: 'it',
    hot: false,
    order: 14,
    type: 'altido',
  },
  {
    name: 'tuscany',
    country: 'it',
    hot: false,
    order: 15,
    type: 'altido',
  },
  {
    name: 'pt',
    country: 'pt',
    hot: false,
    order: 1,
    type: 'altido',
  },
  {
    name: 'algarve',
    country: 'pt',
    hot: false,
    order: 2,
    type: 'altido',
  },
  {
    name: 'cascais',
    country: 'pt',
    hot: false,
    order: 4,
    type: 'altido',
  },
  {
    name: 'charneca-da-caparica',
    country: 'pt',
    hot: false,
    order: 5,
    type: 'altido',
  },
  {
    name: 'costa-da-caparica',
    country: 'pt',
    hot: false,
    order: 6,
    type: 'altido',
  },
  {
    name: 'lisbon',
    country: 'pt',
    hot: true,
    order: 8,
    type: 'altido',
  },
  {
    name: 'porto',
    country: 'pt',
    hot: true,
    order: 9,
    type: 'altido',
  },
  {
    name: 'setubal',
    country: 'pt',
    hot: false,
    order: 10,
    type: 'altido',
  },
  {
    name: 'sintra',
    country: 'pt',
    hot: false,
    order: 11,
    type: 'altido',
  },
  {
    name: 'edinburgh',
    country: 'sco',
    hot: false,
    order: 2,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Edinburgh&country=United+Kingdom&minOccupancy=1`,
  },
  {
    name: 'fr',
    country: 'fr',
    hot: false,
    order: 1,
    type: 'altido',
  },
  {
    name: 'carcassonne',
    country: 'fr',
    order: 2,
    hot: false,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Carcassonne&country=France&minOccupancy=1`,
  },
  {
    name: 'megeve',
    country: 'fr',
    order: 3,
    hot: false,
    type: 'altido',
  },
  {
    name: 'paris',
    country: 'fr',
    order: 4,
    hot: true,
    type: 'altido',
  },
  {
    name: 'es',
    country: 'es',
    hot: false,
    order: 1,
    type: 'altido',
  },
  {
    name: 'madrid',
    country: 'es',
    hot: false,
    order: 2,
    type: 'altido',
    href: (locale: string) =>
      `https://book.stayaltido.com/${locale}/properties?city=Madrid&country=Spain&minOccupancy=1`,
  },
];

// uk: united kingdom
// ie: Ireland
// sco: Scotland
// en: England
// it: Italy
// pt: Portugal
// fr: France
// es: Spain
