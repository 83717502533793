/** @format */

import { i18nContext } from '@/context/i18nContext';
import { GTMDestinationSelection } from '@/lib/google-tag-manager';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useContext, useMemo } from 'react';
import { CityItem } from './Content.style';
import type { City } from './types';
import { getCityUrl } from './utils';
export const CitiesToRender: FC<Readonly<CitiesToRenderProps>> = ({ cities, activeCountry, closeDropdown }) => {
  const { landing: t } = useContext(i18nContext);
  const { locale } = useRouter();

  const activeCities = useMemo(() => {
    const sortCities = (a: City, b: City) => {
      // sort the cities alphabetically, but the "view all" is always first
      // (we're displaying 'view all' when city.name === city.country)
      if (a.name === a.country && b.name !== b.country) return -1;
      if (b.name === b.country && a.name !== a.country) return 1;
      return t[a.name].localeCompare(t[b.name]);
    };
    return cities.filter(city => city.country === activeCountry).sort(sortCities);
  }, [cities, activeCountry]);

  const onCityClick = (city: City) => {
    const type = city.type === 'altido' ? 'travel_and_corporate' : 'coliving';
    GTMDestinationSelection(city.name, city.country, type);
    closeDropdown?.();
  };

  return (
    <>
      {activeCities.map(city => {
        const href = city.href
          ? city.href(locale === 'it' ? locale : 'en')
          : getCityUrl(city.name, locale ?? '', city.type, city.country);
        return (
          <CityItem key={city.name}>
            <a href={href} target="_blank" onClick={() => onCityClick(city)}>
              <LinkContent cityName={city.name} hot={city.hot} viewAll={city.name === city.country} />
            </a>
          </CityItem>
        );
      })}
    </>
  );
};

interface CitiesToRenderProps {
  cities: City[];
  activeCountry: string;
  closeDropdown?: () => void;
}

const LinkContent: FC<Readonly<{ cityName: string; hot: boolean; viewAll?: boolean }>> = ({
  cityName,
  hot,
  viewAll = false,
}) => {
  const { landing: t } = useContext(i18nContext);
  return (
    <>
      {viewAll ? `${t['view_all']} ` : ''}
      {t[cityName]}
      {hot && (
        <>
          <span>-</span>
          <span className="hot">{t['popular']} 🔥</span>
        </>
      )}
    </>
  );
};
